import React, { FC, useEffect, useState } from 'react';
import mainLogo from './../../img/logo_main.png';
import './Teacher.scss';
import Lists from '../../components/Lists';
import { IStu } from '../../types/Student';
import { IUserJSON, IUsers } from '../../types/Interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setInvitesMasR, setInvitesRR, setLoggedIn, setUsersR } from '../../redux/slices/userSlice';
import { setTeachers, setDirection, setTempSearchArr, setTempSearchNum, setTempSearchNumArr, setStudents, setStudentsNumPayType, setStudentsNumPayValue, setStudentsNumPayBalance, setSub, resTempSearchNum } from '../../redux/slices/sheetsSlice/sheetsSlice';
import { getItemLS, setItemLS } from '../../utils/getUserLS';
import LatinaController from '../../API/LatinaController';


interface TeacherProps {
  title?: string;
  varTeacher?: any;
}


const Teacher: FC<TeacherProps> = ({
  title = "Поиск/Добавление"
}) => {
  // const isMountedR = React.useRef(false)

  let navigate = useNavigate();

  // let url = 'http://localhost:8000/api/users/'
  let url = `${process.env.REACT_APP_DOM}/api/student/`
  let tagSearch: string = '';

  const {state} = useLocation();
  if (state) {
    ({ url, tagSearch, title } = state);
  }



  const tempSearchNum = useAppSelector((state) => state.sheets.tempSearchNum)
  const tempSearchArr = useAppSelector((state) => state.sheets.tempSearchArr)
  const directionNum = useAppSelector((state) => state.sheets.directionNum)
  const studentsNum = useAppSelector((state) => state.sheets.studentsNum)
  const studentsNumPay = useAppSelector((state) => state.sheets.studentsNumPay)
  const sheetsDate = useAppSelector((state) => state.sheets.sheetsDate)
  const dispatch = useAppDispatch()

  const token = useAppSelector((state) => state.usersR.token)

  // useEffect(()=> {
  // 	if (isMountedR.current) {
  // 		const jsonR = JSON.stringify(tempSearchNum)
  // 		localStorage.setItem('tempSearchNum',jsonR)
      
  // 	}
  // 	isMountedR.current = true
  // }, [tempSearchNum])
  
  const titleM = title;

  const [isLoading, setLoading] = React.useState(true);

  const [searchValue, setSearchValue] = React.useState("");
  

  useEffect(() => {

    let res

    if (tagSearch) {
      localStorage.setItem('tempTag',tagSearch)
      let data:any
      switch(tagSearch) {
        case 'direction':
          data = getItemLS('directionNum');
          setItemLS('tempSearchNum', data);
          dispatch(setTempSearchNumArr(data))
          console.log('switchDirection')
          LatinaController.directionAll({id:1}).then(res  => {
            console.log('res', res)
            dispatch(setTempSearchArr(res))
          })
          .finally(() => setLoading(false))
          break
        case 'teacher':
          data = getItemLS('teachersNum');
          setItemLS('tempSearchNum', data);
          dispatch(setTempSearchNumArr(data))
          console.log('switchTeacher')
          LatinaController.teacherAll({id:1}).then(res  => {
            console.log('res', res)
            dispatch(setTempSearchArr(res))
          })
          .finally(() => setLoading(false))
          break
        case 'students':
          data = getItemLS('studentsNum');
          setItemLS('tempSearchNum', data);
          dispatch(setTempSearchNumArr(data))
          console.log('switchStudent')
          LatinaController.studentAll({id:1}).then(res  => {
            console.log('res', res)
            dispatch(setTempSearchArr(res))
          })
          .finally(() => setLoading(false))
          break
        case 'subscrip':
          data = getItemLS('subNum');
          setItemLS('tempSearchNum', data);
          dispatch(setTempSearchNumArr(data))
          console.log('switchSubscrip')
          LatinaController.studentAll({id:1}).then(res  => {
            console.log('res', res)
            dispatch(setTempSearchArr(res))

          })
          .finally(() => setLoading(false))
          break
        default:
          break
      }
      // dispatch()
    }

    

    // switch (url)
    // const data =

    // const data = fetch(url, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    // })
    //   .then((res) => {
    //     // console.log(res)
    //     if (res.status >= 400) {
    //       dispatch(setLoggedIn(false));
    //       navigate('/')
    //     }
    //     return res.json()
    //   })
    //   .then((data) => {
    //     console.log('fetch', data)
    //     // dispatch(setTempSearchArr(data))

    //   })
    //   .catch((err) => {
    //     console.log('err',err)
    //     dispatch(setLoggedIn(false));
    //     navigate('/')
    //   })
    //   .finally(() => setLoading(false));
  }, []);

  const onChangeSearchValue = (event: any) => {
    setSearchValue(event.target.value);
  };

  const onclickInvite = (id: any) => {
    id = Number(id);
    if (tagSearch == 'subscrip') {
      dispatch(resTempSearchNum())
      dispatch(setTempSearchNum(id))
      // console.log('sb')
      dispatch(setSub());
      localStorage.setItem('tempTag','')
      localStorage.setItem('tempSearchNum','')
      navigate("/subscripV2", {state: {constList: 25}});
    } else {
      dispatch(setTempSearchNum(id))
    }
  };

  const subSC = async(arr:any) => {
    const prom: any = []
    for (let i=0; i<arr.length; i++) {
      prom[i] = await fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${arr[i]}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Token ${token}`,
        },
      })
      .then((res) => res.json())
      .then((dataSC) => {
        return dataSC
      })
    }
    return await Promise.all([...prom])
  }

  const funSubscriptExp = async ({direction}: { direction?: number}) => {
    let stuSub
    direction = directionNum[0]
    switch(direction) {
      case 33:
        break;
      case 34:
        break;

      // #LA: АфроДэнсхол 
      case 18:
        try {
          stuSub = await LatinaController.studentObjSubSpez({id:1})
        } catch (e: any) {
          console.log(e)
        }
        await funSubscrib(stuSub)
        break;

      // #LA: Лэди Таррашо
      case 19:
        try {
          stuSub = await LatinaController.studentObjSubSpez({id:1})
        } catch (e: any) {
          console.log(e)
        }
        await funSubscrib(stuSub)
        break;
      default:
        try {
          stuSub = await LatinaController.studentObjSubNotSpez({id:1})
        } catch (e: any) {
          console.log(e)
        }
        await funSubscrib(stuSub)
        break
    }
  }

  const funSubscrib = async (stuSub:any) => {

    // let stuSub
    // try {
    //   stuSub = await LatinaController.studentObjSubAll({id:1})
    // } catch (e: any) {
    //   console.log(e)
    // }
    // console.log('stuSub', stuSub)

    if(!stuSub) return

    for (let num of tempSearchNum) {

      console.log('val', num)
      // console.log('stuSub[val]', stuSub[num])
      // const dataF = fetch(`http://${process.env.REACT_APP_DOM}/api/student/${val}/`, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json; charset=UTF-8',
      //     'Authorization': `Token ${token}`,
      //   },
      // })
      // .then((res) => res.json())
      // .then((data) => {
        
      // })
      // .catch((err) => console.log(err))
      // .finally(() => {});

      if (stuSub && stuSub[num].subscriptionsList) {

        let abn: Array<any>

        const arr =  stuSub[num].subscriptions as Array<any>
        
        // if(arr.length === 1) {
        //   // abn = [...arr]
        //   abn = arr.map(val => val.slice(1))
        // } else {
        //   const abnFlam = (arr.filter(val => {
        //     return val[0] === 'B'
        //   }).map(val => val.slice(1)))
        //   const abnNoFlam = (arr.filter(val => {
        //     return val[0] !== 'B'
        //   }).map(val => val.slice(1)))
        //   abn = [...abnFlam, ...abnNoFlam]
        // }

        // subSC(abn).then(res => {
          let kontrol = false
          for (let val of arr) {
            if(directionNum[0] == '33' || directionNum[0] == '34' ) {
              if(val.price === 2500) {
              const infoAbn = JSON.parse(val.info)

              const oldDate = new Date(Date.parse(val.dateBegin))
              let dateKorrekt = 1
              if(process.env.REACT_APP_KORREKT_DATE) {
                dateKorrekt = Number(process.env.REACT_APP_KORREKT_DATE)
              }

              let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
              let newDate2 = new Date(newDate.setDate(newDate.getDate()+dateKorrekt))

              const oneDate = new Date(sheetsDate)

              if (oneDate.getTime() > newDate2.getTime()) {

                val.isActive = false
              } else {
                if (infoAbn.dateBonusParty) {
                  if (val.balance > 0) {
                    const id = val.studentId
                    const value = val.numberSubscription
                    const quantity = val.quantity
                    const balanceNew = quantity - (val.balance - 1)
                    // console.log('teacher-dataFF', val, balanceNew)
                    dispatch(setStudentsNumPayType({id, value: 'абн'}))
                    dispatch(setStudentsNumPayValue({id, value}))
                    dispatch(setStudentsNumPayBalance({id, balanceNew}))
                    kontrol = true
                    break
                  }
                } else {
                  infoAbn.dateBonusParty = [sheetsDate]
                  const id = val.studentId
                  const value = val.numberSubscription
                  // console.log('teacher2-dataFF', val, value)
                  dispatch(setStudentsNumPayType({id, value: 'абн'}))
                  dispatch(setStudentsNumPayValue({id,value}))
                  dispatch(setStudentsNumPayBalance({id, balanceNew: 'бон'}))
                  kontrol = true
                  break
                }
              }
                
              } else {
                if (val.balance > 0) {
                  const id = val.studentId
                  const value = val.numberSubscription
                  const quantity = val.quantity
                  const balanceNew = quantity - (val.balance - 1)
                  // console.log('teacher3-dataFF', val, balanceNew)
                  dispatch(setStudentsNumPayType({id, value: 'абн'}))
                  dispatch(setStudentsNumPayValue({id, value}))
                  dispatch(setStudentsNumPayBalance({id, balanceNew}))
                  kontrol = true
                  break
                }
              }
            } else {
              if(val.price === 2500) {
                const infoAbn = JSON.parse(val.info)

                const oldDate = new Date(Date.parse(val.dateBegin))
                let dateKorrekt = 1
                if(process.env.REACT_APP_KORREKT_DATE) {
                  dateKorrekt = Number(process.env.REACT_APP_KORREKT_DATE)
                }

                let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
                let newDate2 = new Date(newDate.setDate(newDate.getDate()+dateKorrekt))


                const oneDate = new Date(sheetsDate)


                if (oneDate.getTime() > newDate2.getTime()) {

                  val.isActive = false
                } else {
                    if (val.balance > 0) {
                      const id = val.studentId
                      const value = val.numberSubscription
                      const quantity = val.quantity
                      const balanceNew = quantity - (val.balance - 1)
                      // console.log('teacher4-dataFF', val, balanceNew)
                      dispatch(setStudentsNumPayType({id, value: 'абн'}))
                      dispatch(setStudentsNumPayValue({id, value}))
                      dispatch(setStudentsNumPayBalance({id, balanceNew}))
                      kontrol = true
                      break
                    }
                }
                  
                } else {
                  if (val.balance > 0) {
                    const id = val.studentId
                    const value = val.numberSubscription
                    const quantity = val.quantity
                    const balanceNew = quantity - (val.balance - 1)
                    // console.log('teacher5-dataFF', val, balanceNew)
                    dispatch(setStudentsNumPayType({id, value: 'абн'}))
                    dispatch(setStudentsNumPayValue({id, value}))
                    dispatch(setStudentsNumPayBalance({id, balanceNew}))
                    kontrol = true
                    break
                  }
                }
            }
          }
          if (kontrol === false) {
            console.log('val339', num)
            console.log(stuSub[num])
            const id = stuSub[num].id
            if(!studentsNumPay[`${id}-stuNumPay`] ||  studentsNumPay[`${id}-stuNumPay`].type == 'абн') {
              dispatch(setStudentsNumPayType({id, value: 'абн'}))
              dispatch(setStudentsNumPayValue({id, value: 'нет!'}))
              dispatch(setStudentsNumPayBalance({id, balanceNew: ''}))
            }
          }

        // })


      } else {
        
        // const id = stuSub[num].id
        const id = num
        if(!studentsNumPay[`${id}-stuNumPay`] ||  studentsNumPay[`${id}-stuNumPay`].type == 'абн') {
          
          // console.log('teacher6-dataFF', id )
          dispatch(setStudentsNumPayType({id, value: 'абн'}))
          dispatch(setStudentsNumPayValue({id, value: 'н/д'}))
          dispatch(setStudentsNumPayBalance({id, balanceNew: ''}))
        }
      }

    }



    // const data = fetch("http://localhost:8000/api/subsconst/")
    // .then((res) => res.json())
    // .then((data) => {

      // for (let item of data) {
      // 	for (let val of tempSearchNum) {
      // 		if(val == item.id_users && item.status === true) {

      // 		}
      // 	}
      // }

      // tempSearchNum.filter((itemTemp: any) => {
      // 	if (!studentsNum.includes(itemTemp)) {


      // 		studentsNumPay[`${itemTemp}-stuNumPay`] = {
      // 			...studentsNumPay[`${itemTemp}-stuNumPay`],
      // 			type: 'абн',
      // 			value: 'нет',
      // 			id: itemTemp,
      // 		}
      // 		const jsonRRNum = JSON.stringify(studentsNumPay)
      // 		localStorage.setItem('studentsNumPay',jsonRRNum)


      // 	}
      // })

    // })
    // .catch((err) => console.log(err))
    // .finally(() => setLoading(false));
  }

  const onInvites = () => {

    switch(tagSearch) {
      case 'teacher':
        dispatch(setTeachers());
        navigate("/list", {state: {constList: 25}});
        break;
      case 'direction':
        dispatch(setDirection());
        navigate("/list", {state: {constList: 25}});
        break;
      case 'students':
        dispatch(setStudents());
        funSubscriptExp({});
        navigate("/list", {state: {constList: 25}});
        break;
      case 'subscrip':
        // console.log('sb')
        // dispatch(setSub());
        navigate("/subscripV2", {state: {constList: 25}});
        break;
      default:
        break
    }


    localStorage.setItem('tempTag','')
    localStorage.setItem('tempSearchNum','')

    // navigate("/list", {state: {constList: 25}});
  };

  return (
    <div className="Students">
      <div className="lat-form">
        <div className="lat-form__container lat-form__container_shadow">
          <img className="logo" src={mainLogo} alt="логотип"></img>
          <div>{titleM}</div>
          <Lists
            isLoading={isLoading}
            onChangeSearchValue={onChangeSearchValue}
            searchValue={searchValue}
            invites={tempSearchNum}
            onclickInvite={onclickInvite}
            students={tempSearchArr}
            tagSearch={tagSearch}
          />
          <div>
            <button onClick={() => onInvites()}>Обновить</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teacher;
