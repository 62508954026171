import API, { LatinaAPI } from "./LatinaAPI"


export class LatinaController {
  private readonly api: LatinaAPI

  constructor() {
    this.api = API
  }

  async statement(data: any) {
    const response = await this.api.statement(data)
    return response.data
  }

  async subscriptionCreate(data: any) {
    const response = await this.api.subscriptionCreate(data)
    return response.data
  }

  async userCreate(data: any) {
    const response = await this.api.userCreate(data)
    return response.data
  }

  async authLogin(data: any) {
    const response = await this.api.authLogin(data)
    return response.data
  }

  async profile() {
    const response = await this.api.profile()
    return response.data
  }

  async statementAll(data: any) {
    const response = await this.api.statementAll(data)
    return response.data
  }

  async studentAll(data: any) {
    const response = await this.api.studentAll(data)
    return response.data
  }

  async studentOne(data: any) {
    const response = await this.api.studentOne(data)
    return response.data
  }

  async teacherAll(data: any) {
    const response = await this.api.teacherAll(data)
    return response.data
  }

  async directionAll(data: any) {
    const response = await this.api.directionAll(data)
    return response.data
  }

  async studentObjSubAll(data: any) {
    const response = await this.api.studentObjSubAll(data)
    return response.data
  }

  async subscriptionAll(data: any) {
    const response = await this.api.subscriptionAll(data)
    return response.data
  }

  async subscriptionOne(data: any) {
    const response = await this.api.subscriptionOne(data)
    return response.data
  }

  async statementOne(data: any) {
    const response = await this.api.statementOne(data)
    return response.data
  }

  async userBirthday(data: any) {
    const response = await this.api.userBirthday(data)
    return response.data
  }

  async statementLessonAll(data: any) {
    const response = await this.api.statementLessonAll(data)
    return response.data
  }

  async lessonAll(data: any) {
    const response = await this.api.lessonAll(data)
    return response.data
  }

  async token() {
    const response = await this.api.token()
    return response.data
  }

  async me() {
    const response = await this.api.me()
    return response.data
  }

  async addSubscript(data: any) {
    const response = await this.api.addSubscript(data)
    return response.data
  }

  async addStatement(data: any) {
    const response = await this.api.addStatement(data)
    return response.data
  }

  async dataStatAll(data: any) {
    const response = await this.api.dataStatAll(data)
    return response.data
  }

  async subscriptionExtension(data: any) {
    const response = await this.api.subscriptionExtension(data)
    return response.data
  }

  async statZp(data: any) {
    const response = await this.api.statZp(data)
    return response.data
  }

  async subscriptionDelete(data: any) {
    const response = await this.api.subscriptionDelete(data)
    return response.data
  }

  async lessonDelete(data: any) {
    const response = await this.api.lessonDelete(data)
    return response.data
  }

  async infoUser(data: any) {
    const response = await this.api.infoUser(data)
    return response.data
  }

  async studentObjSubSpez(data: any) {
    const response = await this.api.studentObjSubSpez(data)
    return response.data
  }

  async studentObjSubNotSpez(data: any) {
    const response = await this.api.studentObjSubNotSpez(data)
    return response.data
  }

  async statementTeacher(data: any) {
    const response = await this.api.statementTeacher(data)
    return response.data
  }

  async statementTeacherOne(data: any) {
    const response = await this.api.statementTeacherOne(data)
    return response.data
  }

}

export default new LatinaController()
