import React, { FC, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import mainLogo from './../../img/logo_main.png';
import "react-datepicker/dist/react-datepicker.css";
import './List.scss';
import ListOnly from '../../components/ListOnly';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resDirection, resStudents, resTeacher, setSheetsDate, setValueCash } from '../../redux/slices/sheetsSlice/sheetsSlice';
import { getItemLS, setItemLS } from '../../utils/getUserLS';
import ListOnlyStu from '../../components/ListOnlyStu';
import NavBar from '../../components/NavBar';
import Modal from '../../components/Modal/Modal';
import LatinaController from '../../API/LatinaController';
import { resLogged, setLoggedIn } from '../../redux/slices/userSlice';
import { UserService } from '../../services/user.service';

import api from './../../API'

interface ListProps {
	title?: string;
	number?:number;
}

const List: FC<ListProps> = ({
	title= "Ведомость", number=1,
}) => {
	let navigate = useNavigate();
	const {state} = useLocation();
	if(state) {
		const { constList } = state;
		// console.log(constList)
	}
	const isMountedR = React.useRef(false)
	const titleM = title;
	// const [startDate, setStartDate] = useState(new Date());
	const [sendDisable, setSendDisable] = useState(false);
	const [modal, setModal] = useState(false);
	const teachers = useAppSelector((state) => state.sheets.teachers)
	const students = useAppSelector((state) => state.sheets.students)
	const studentsNumPay = useAppSelector((state) => state.sheets.studentsNumPay)
	const tempStudents = useAppSelector((state) => state.sheets.tempStudents)
	const tempStudentsNum = useAppSelector((state) => state.sheets.tempStudentsNum)
  const directionNum = useAppSelector((state) => state.sheets.directionNum)
	const direction = useAppSelector((state) => state.sheets.direction)
	const sheetsDate = useAppSelector((state) => state.sheets.sheetsDate)
	const valueCash = useAppSelector((state) => state.sheets.valueCash)
  const userName = useAppSelector((state) => state.usersR.user.username)
	const dispatch = useAppDispatch()



  const token = useAppSelector((state) => state.usersR.token)



	// useEffect(()=> {
	// 	if (isMountedR.current) {
	// 		const jsonR = JSON.stringify(teachers)
	// 		localStorage.setItem('teachers',jsonR)
	// 	}
	// 	isMountedR.current = true
	// }, [teachers])

  useEffect(()=> {
    if (!isMountedR.current) {
      
      auth()

      const valueCashInput = document.getElementById('valueCashInput') as HTMLInputElement
      if ( Number.isNaN(valueCash.toString()) || valueCash == null || valueCash.toString() == 'NaN') {
        valueCashInput.value = ''
      } else {
      valueCashInput.value = valueCash.toString()
      }
		}
		isMountedR.current = true
	}, [])

  const auth = async () => {
    try {
      const profile = await UserService.getProfile()
      // const me = await LatinaController.me()
      // console.log(me)
      // console.log('profile', profile.data)
      // api.getUserData()
      // .then((res: any) => {
      // })
      // .catch(err => {
      //   localStorage.removeItem('token')
      //   dispatch(setLoggedIn(false));
      //   dispatch(resLogged());
      // })
    } catch (e) {
      localStorage.removeItem('token')
      dispatch(setLoggedIn(false));
      dispatch(resLogged());
      navigate('/');
    }
  }

	const resetTeachers = () => {
		dispatch(resTeacher())
	}
	const resetDirection = () => {
		dispatch(resDirection())
	}
	const resetStudents = () => {
		dispatch(resStudents())
	}

	const onDirection = () => {
		// const data = getItemLS('directionNum');
		// setItemLS('tempSearchNum', data);
		// navigate('/teacher', {state: {url: "http://localhost:8000/api/direction/", tagSearch: 'direction', title: 'Направление'}} );
		navigate('/teacher', {state: {url: `${process.env.REACT_APP_DOM}/api/direction/`, tagSearch: 'direction', title: 'Направление'}} );
	}
	const onTeachers = () => {
		// const data = getItemLS('teachersNum');
		// setItemLS('tempSearchNum', data);
		// navigate('/teacher', {state: {url: "http://localhost:8000/api/users/", tagSearch: 'teacher', title: 'Преподаватели'}} );
		navigate('/teacher', {state: {url: `${process.env.REACT_APP_DOM}/api/coath/`, tagSearch: 'teacher', title: 'Преподаватели'}} );
	}
	const onStudents = () => {
		// const data = getItemLS('teachersNum');
		// setItemLS('tempSearchNum', data);
		// navigate('/teacher', {state: {url: "http://localhost:8000/api/users/", tagSearch: 'students', title: 'Ученики'}} );
		navigate('/teacher', {state: {url: `${process.env.REACT_APP_DOM}/api/student/`, tagSearch: 'students', title: 'Ученики'}} );
	}

  const onStudentsNew = () => {
    navigate('/add-students', {state: {url: `${process.env.REACT_APP_DOM}/api/users/`, tagSearch: 'students', title: 'Ученики'}} );
  }

	const onSend = async() => {
    const th = document.querySelector('.sheets__send');
    // console.log(th)
    if (th) {
      // th.classList.add('sheets__send_disable')
      setSendDisable(true)
    }

    const studentsPay = [...students, ...tempStudents]
    let isValidatePay = true
    const arrValidatePayNone = []
    // let sendMessageArr: any = []

    for (let item of studentsPay) {
      if (studentsNumPay[`${item.id}-stuNumPay`].type === 'абн' && (studentsNumPay[`${item.id}-stuNumPay`].value === 'нет' || studentsNumPay[`${item.id}-stuNumPay`].value === 'нет!' || studentsNumPay[`${item.id}-stuNumPay`].value === 'ожд!' || studentsNumPay[`${item.id}-stuNumPay`].value === 'н/д')) {
        isValidatePay = false
        if (item.fio) {
          arrValidatePayNone.push(item.fio)
        } else {
          const info = JSON.parse(item.info)
          arrValidatePayNone.push(info.info)
        }
      }
    }

    if (teachers.length === 0) {
      alert('Введите преподавателей')
    } else if (direction.length === 0) {
      alert('Введите направление')
    } else if (studentsPay.length === 0) {
      alert('Введите учеников')
    } else if (valueCash == null || Number.isNaN(Number(valueCash)) || valueCash.toString() == '') {

      alert('Введите значение наличных в кассе')
    } else if (!isValidatePay) {
      alert(`Введите значение оплаты ${arrValidatePayNone}`)
    } else {



      const arrCoa = teachers.map(item => {
        return item.id
      })

      const arrDir = direction.map((item: any) => {
        return item.id
      })

      const nameDir = direction.map((item: any) => {
        return item.name
      })
      const nameCoa = teachers.map((item: any) => {
        if(item.fio) {
          return item.fio
        } else {
          const infoStu = JSON.parse(item.info)
          return infoStu.info
        }
      })

      const infoStat = {
        dir: nameDir,
        coa: nameCoa
      }

      const daySend = JSON.stringify(new Date()).substring(1,11)
      // console.log(daySend)

    
      const dataStatement = {
        coath: arrCoa.toString(),
        direction: arrDir.toString(),
        number: '0',
        prefix: 'S',
        pub_date: sheetsDate,
        info: JSON.stringify(infoStat),
        'pub_date_proba_pera': daySend,
      }



      // console.log(students)
      // console.log(studentsNumPay)

      // for (let item of students) {
      // 	const obj: any = {}
      // 	obj.id_users = item.id;
      // 	obj.info =  JSON.stringify({info:item.fio});
      // 	obj.paymet = studentsNumPay[`${item.id}-stuNumPay`].type;
      // 	obj.value = studentsNumPay[`${item.id}-stuNumPay`].value;
      // 	obj.prefix = 'L';
      // 	obj.id_statement = '';
      // 	obj.number = '0'
      // }


      // const dataLesson = {
      //   id_statement: '1',
      //   id_users: '1',
      //   number: '5',
      //   paymet: 'нал',
      //   prefix: 'L',
      //   value: '400нал',
      // }

      const dataAlert = {
        valAbn: 0,
        valCash: 0,
        valNonCash: 0,
        valueCash,
        valBonus: 0,
        valHand: [] as Array<string>,
      }

      const dataTest = {
        students,
        studentsNumPay
      }

      const res = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram/msg`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataTest)
      })
      // console.log(JSON.stringify(dataTest))

      let statementNumber:any

      const dataSend = {
        dataStatement,
        studentsPay,
        studentsNumPay,
        valueCash,
        sheetsDate,
        direction,
        userName
      }
      // LatinaController.addStatement(dataSend)

      try {

        const resAddStatement = await baseSend()

        if(resAddStatement) {

          console.log('ГОТОВО')
          // console.log('resAddStatement.dataAlert', resAddStatement.dataAlert)
          // console.log('resAddStatement.dataTestUser', resAddStatement.dataTestUser)

          setTimeout(() => {
            const th = document.querySelector('.sheets__send');

            if (th) {
              th.classList.add('sheets__send_success')
              setSendDisable(false)
            }

          }, 2100)

          setTimeout(() => {

            const dataTestUser = {
              login: userName,
              message: resAddStatement.dataTestUser.message
            }
        
            const resUser = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataTestUser)
            })

            if(userName !== process.env.REACT_APP_USER_ONE) {



            const dataTest = {
              login: process.env.REACT_APP_USER_ONE,
              message: resAddStatement.dataTestUser.message
            }
        
            const res = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataTest)
            })

            }

            if(userName !== process.env.REACT_APP_USER_TWO) {


            const dataTest2 = {
              login: process.env.REACT_APP_USER_TWO,
              message: resAddStatement.dataTestUser.message
            }
        
            const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataTest2)
            })

            }

          alert(`${resAddStatement.dataTestUser.message}`);
          },
          2000)

        }

      } catch (e) {
        console.log(e)

				const th = document.querySelector('.sheets__send');

				if (th) {
					th.classList.add('sheets__send_error')
          setSendDisable(false)
				}
				alert("Ошибка! Данные не отправлены");
      }


      /*

      console.log(dataStatement)
      // console.log(dataLesson)
      // fetch('http://localhost:8000/api/statement/', {
      fetch(`http://${process.env.REACT_APP_DOM}/api/statement/`, {
			method: 'POST',
			headers: {
			accept: 'application/json',
			'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Token ${token}`,
			},
			credentials: 'same-origin',
			body: JSON.stringify(dataStatement)
			})
			.then((res) => res.json())
			.then(data => {
				console.log('data',data);
        statementNumber = (data.id).toString()
				data = {...data, number: (data.id).toString()}
				console.log('dataUpdate',data);
				// fetch(`http://localhost:8000/api/statement/${data.id}/`, {
				fetch(`${process.env.REACT_APP_DOM}/api/statement/${data.id}/`, {
					method: 'PATCH',
					headers: {
						accept: 'application/json',
						'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Token ${token}`,
					},
					credentials: 'same-origin',
					body: JSON.stringify(data)
					})
					.catch(e => {
						console.log('statement', e)
					})

        const studentsPay = [...students, ...tempStudents]
				for (let item of studentsPay) {
					const obj: any = {}
					obj.id_users = (item.id).toString();
					if (item.info) {
						const infoStu = JSON.parse(item.info)
						obj.info =  JSON.stringify({info:infoStu.info});
					} else if (item.fio) {
						obj.info = JSON.stringify({info:item.fio})
					} else {
						obj.info = JSON.stringify({info:''})
					}
          if (studentsNumPay[`${item.id}-stuNumPay`].balanceNew === 'бон') {
            obj.paymet = `${studentsNumPay[`${item.id}-stuNumPay`].type}*`;
          } else {
            obj.paymet = `${studentsNumPay[`${item.id}-stuNumPay`].type}`;
          }
          obj.value = studentsNumPay[`${item.id}-stuNumPay`].value;
					obj.prefix = 'L';
					obj.id_statement = (data.id).toString();
					obj.number = '0'
					console.log('obj', obj)
          if (obj.paymet == 'нал') {
            dataAlert.valCash++
          } else if (obj.paymet == 'б/н') {
            dataAlert.valNonCash++
          } else if (obj.paymet == 'абн') {
            dataAlert.valAbn++
          } else if (obj.paymet == 'абн*') {
            dataAlert.valBonus++
          } else if (obj.paymet == 'руч') {
            dataAlert.valHand.push(JSON.stringify(obj.value))
          }
					// fetch('http://localhost:8000/api/lesson/', {
					fetch(`${process.env.REACT_APP_DOM}/api/lesson/`, {
						method: 'POST',
						headers: {
							accept: 'application/json',
							'Content-Type': 'application/json; charset=UTF-8',
              'Authorization': `Token ${token}`,
						},
						credentials: 'same-origin',
						body: JSON.stringify(obj)
					})
					.then((res) => res.json())
					.then(dataLes => {
						dataLes = {...dataLes, number: (dataLes.id).toString()}
						console.log('dataLesUpdate',data);
						// fetch(`http://localhost:8000/api/lesson/${dataLes.id}/`, {
						fetch(`${process.env.REACT_APP_DOM}/api/lesson/${dataLes.id}/`, {
							method: 'PATCH',
							headers: {
								accept: 'application/json',
								'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Token ${token}`,
							},
							credentials: 'same-origin',
							body: JSON.stringify(dataLes)
						})
            .then((res) => res.json())
            .then(dataLL => {

              if (dataLL.paymet == 'абн') {
                if (dataLL.value != 'нет') {

                  // if (dataLL.value == 'бонус') {

                    
                  // } else {
                    
                    const numAbn = dataLL.value.slice(1)
    
                    fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${numAbn}/`, {
                      method: 'GET',
                      headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Token ${token}`,
                      },
                      credentials: 'same-origin',
                    })
                    .then((res) => res.json())
                    .then(dataAbn => {
                      const infoAbn = JSON.parse(dataAbn.info)
                      
                      dataAbn.balance = dataAbn.balance - 1

                      if(dataAbn.balance < 2) {
                        console.log({studentId: Number(dataAbn.id_users), balance: Number(dataAbn.balance), abnId: Number(dataAbn.id)})
                        // sendMessageArr.push({studentId: Number(dataAbn.id_users), balance: Number(dataAbn.balance), abnId: Number(dataAbn.id)})
                      }

                      if (dataAbn.balance <= 0) {
                        if (dataAbn.price == 2500) {
                          if (infoAbn.dateBonusParty) {
                            dataAbn.status = false
                          }
                        } else {
                          dataAbn.status = false
                        }
                      }
                      
                      if (infoAbn.date) {
                        infoAbn.date = [...infoAbn.date, sheetsDate]
                      } else {
                        infoAbn.date = [sheetsDate]
                        if (dataAbn.price == 2500) {
                          dataAbn.pub_date = JSON.stringify(sheetsDate).slice(1,-1)
                        }
                      }
                      if (infoAbn.lessons) {
                        infoAbn.lessons = [...infoAbn.lessons, {name: direction[0].name, date: sheetsDate}]
                      } else {
                        infoAbn.lessons = [{name: direction[0].name, date: sheetsDate}]
                      }
                      dataAbn.info = JSON.stringify(infoAbn)
    
    
    
                      if(dataAbn.price == 2500) {
        
                        const oldDate = new Date(Date.parse(dataAbn.pub_date))
        
                        let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
                        let newDate2 = new Date(newDate.setDate(newDate.getDate()+1))
        
                        const oneDate = new Date(sheetsDate)
        
                        if (oneDate.getTime() > newDate2.getTime()) {
        
                          dataAbn.status = false
                        } else {
                          // if (directionNum[0] == '33' || directionNum[0] == '34') {
                            // if (infoAbn.dateBonusParty) {
                              // if (dataAbn.balance > 0) {

                              
      
                                // const infoAbn = JSON.parse(dataAbn.info)
      
                              // }
                            // } else {
                              // infoAbn.dateBonusParty = [new Date]
                              // const id = Number(dataAbn.id_users);
      
                              // // const infoAbn = JSON.parse(dataAbn.info)
                              // // if (infoAbn.date) {
                              // //   infoAbn.date = [...infoAbn.date, new Date]
                              // // } else {
                              // //   infoAbn.date = [new Date]
                              // // }
                              // dataAbn.info = JSON.stringify(infoAbn)
      
                            // }
    
                          // }
                        }
                      } else {

                      }
                      
    
                      // if (dataAbn.price == 2500) {
                      //   const oldDate = new Date(Date.parse(dataAbn.pub_date))
    
                      //   let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
                      //   let newDate2 = new Date(newDate.setDate(newDate.getDate()+2))
    
                      //   const oneDate = new Date()
      
                      //   if (oneDate.getTime() > newDate2.getTime()) {
    
                      //     dataAbn.status = false
                      //   } else {
    
                      //   }
    
                      // }
    
                      fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${numAbn}/`, {
                        method: 'PATCH',
                        headers: {
                          accept: 'application/json',
                          'Content-Type': 'application/json; charset=UTF-8',
                          'Authorization': `Token ${token}`,
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(dataAbn)
                      })
                    })
                  // }


                
                }
              } else if(dataLL.paymet == 'абн*') {


                if (dataLL.value != 'нет') {

                  // if (dataLL.value == 'бонус') {

                    
                  // } else {
                    
                    const numAbn = dataLL.value.slice(1)
    
                    fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${numAbn}/`, {
                      method: 'GET',
                      headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Token ${token}`,
                      },
                      credentials: 'same-origin',
                    })
                    .then((res) => res.json())
                    .then(dataAbn => {
                      const infoAbn = JSON.parse(dataAbn.info)
                      
                      if (dataAbn.balance <= 0) {
                        if (dataAbn.price == 2500) {
                          if (infoAbn.dateBonusParty) {
                            dataAbn.status = false
                          }
                        } else {
                          dataAbn.status = false
                        }
                      }


                      
                      if (infoAbn.dateBonusParty) {
                        dataAbn.balance = dataAbn.balance - 1
        
                        if (dataAbn.balance <= 0) {
                          dataAbn.status = false
                        }

                        if (infoAbn.date) {
                          infoAbn.date = [...infoAbn.date, sheetsDate]
                        } else {
                          infoAbn.date = [sheetsDate]
                        }

                        if (infoAbn.lessons) {
                          infoAbn.lessons = [...infoAbn.lessons, {name: direction[0].name, date: sheetsDate}]
                        } else {
                          infoAbn.lessons = [{name: direction[0].name, date: sheetsDate}]
                        }
                        
                      } else {
                        infoAbn.dateBonusParty = [sheetsDate]
                        if (dataAbn.balance <= 0) {
                          if (dataAbn.price == 2500) {
                            if (infoAbn.dateBonusParty) {
                              dataAbn.status = false
                            }
                          } else {
                            dataAbn.status = false
                          }
                        }
                      }
                      dataAbn.info = JSON.stringify(infoAbn)


                      if(dataAbn.price == 2500) {
        
                        const oldDate = new Date(Date.parse(dataAbn.pub_date))
        
                        let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
                        let newDate2 = new Date(newDate.setDate(newDate.getDate()+1))
        
                        const oneDate = new Date(sheetsDate)
        
                        if (oneDate.getTime() > newDate2.getTime()) {
        
                          dataAbn.status = false
                        } else {
                          // if (directionNum[0] == '33' || directionNum[0] == '34') {
                            // if (infoAbn.dateBonusParty) {
                              // if (dataAbn.balance > 0) {

                              
      
                                // const infoAbn = JSON.parse(dataAbn.info)
      
                              // }
                            // } else {
                              // infoAbn.dateBonusParty = [new Date]
                              // const id = Number(dataAbn.id_users);
      
                              // // const infoAbn = JSON.parse(dataAbn.info)
                              // // if (infoAbn.date) {
                              // //   infoAbn.date = [...infoAbn.date, new Date]
                              // // } else {
                              // //   infoAbn.date = [new Date]
                              // // }
                              // dataAbn.info = JSON.stringify(infoAbn)
      
                            // }
    
                          // }
                        }
                      } else {

                      }
                      
    
                      // if (dataAbn.price == 2500) {
                      //   const oldDate = new Date(Date.parse(dataAbn.pub_date))
    
                      //   let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
                      //   let newDate2 = new Date(newDate.setDate(newDate.getDate()+2))
    
                      //   const oneDate = new Date()
      
                      //   if (oneDate.getTime() > newDate2.getTime()) {
    
                      //     dataAbn.status = false
                      //   } else {
    
                      //   }
    
                      // }
    
                      fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${numAbn}/`, {
                        method: 'PATCH',
                        headers: {
                          accept: 'application/json',
                          'Content-Type': 'application/json; charset=UTF-8',
                          'Authorization': `Token ${token}`,
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify(dataAbn)
                      })
                    })
                  // }


                
                }




              } else {

              }
            })

					})

				}


			})
			.then( async val => {
        // MessageSender()




				console.log('ГОТОВО',val)
				// const th = (document.querySelector(`.lat-form__container`) as HTMLElement);
				// console.log(th)
				// if (th) {
				// 	th.classList.add('lat-form__container_success')
				// }
        setTimeout(() => {
          const th = document.querySelector('.sheets__send');
          // console.log(th)
          if (th) {
            th.classList.add('sheets__send_success')
            setSendDisable(false)
          }

        }, 2100)

        setTimeout(() => {

          const dataTestUser = {
            login: userName,
            message: `Данные отправлены!
            Ведомость: ${statementNumber};
            Дата: ${JSON.stringify(sheetsDate).substring(1,11)};
            Направление: ${infoStat.dir};
            Преподаватели: ${infoStat.coa};
            Всего человек: ${dataAlert.valAbn + dataAlert.valNonCash + dataAlert.valCash + dataAlert.valBonus + dataAlert.valHand.length};
            Абонементов: ${dataAlert.valAbn};
            Безнал: + ${dataAlert.valNonCash * 400};
            Наличных: + ${dataAlert.valCash * 400};
            Нал + б/н: ${dataAlert.valNonCash * 400 + dataAlert.valCash * 400}
            В кассе: ${dataAlert.valueCash};
            Бонусных: ${dataAlert.valBonus};
            Значения в ручную: ${dataAlert.valHand};
            `
          }
      
          const resUser = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTestUser)
          })

          if(userName !== process.env.REACT_APP_USER_ONE) {



          const dataTest = {
            login: process.env.REACT_APP_USER_ONE,
            message: `Данные отправлены!
            Ведомость: ${statementNumber};
            Дата: ${JSON.stringify(sheetsDate).substring(1,11)};
            Направление: ${infoStat.dir};
            Преподаватели: ${infoStat.coa};
            Всего человек: ${dataAlert.valAbn + dataAlert.valNonCash + dataAlert.valCash + dataAlert.valBonus + dataAlert.valHand.length};
            Абонементов: ${dataAlert.valAbn};
            Безнал: + ${dataAlert.valNonCash * 400};
            Наличных: + ${dataAlert.valCash * 400};
            Нал + б/н: ${dataAlert.valNonCash * 400 + dataAlert.valCash * 400}
            В кассе: ${dataAlert.valueCash};
            Бонусных: ${dataAlert.valBonus};
            Значения в ручную: ${dataAlert.valHand};
            `
          }
      
          const res = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTest)
          })

          }

          if(userName !== process.env.REACT_APP_USER_TWO) {


          const dataTest2 = {
            login: process.env.REACT_APP_USER_TWO,
            message: `Данные отправлены!
            Ведомость: ${statementNumber};
            Дата: ${JSON.stringify(sheetsDate).substring(1,11)};
            Направление: ${infoStat.dir};
            Преподаватели: ${infoStat.coa};
            Всего человек: ${dataAlert.valAbn + dataAlert.valNonCash + dataAlert.valCash + dataAlert.valBonus + dataAlert.valHand.length};
            Абонементов: ${dataAlert.valAbn};
            Безнал: + ${dataAlert.valNonCash * 400};
            Наличных: + ${dataAlert.valCash * 400};
            Нал + б/н: ${dataAlert.valNonCash * 400 + dataAlert.valCash * 400}
            В кассе: ${dataAlert.valueCash};
            Бонусных: ${dataAlert.valBonus};
            Значения в ручную: ${dataAlert.valHand};
            `
          }
      
          const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTest2)
          })

          }

        alert(`Данные отправлены!
        Ведомость: ${statementNumber};
        Дата: ${JSON.stringify(sheetsDate).substring(1,11)};
				Направление: ${infoStat.dir};
				Преподаватели: ${infoStat.coa};
        Всего человек: ${dataAlert.valAbn + dataAlert.valNonCash + dataAlert.valCash + dataAlert.valBonus + dataAlert.valHand.length};
        Абонементов: ${dataAlert.valAbn};
        Безнал: + ${dataAlert.valNonCash * 400};
        Наличных: + ${dataAlert.valCash * 400};
				Нал + б/н: ${dataAlert.valNonCash * 400 + dataAlert.valCash * 400}
        В кассе: ${dataAlert.valueCash};
        Бонусных: ${dataAlert.valBonus};
        Значения в ручную: ${dataAlert.valHand};
        `);
        },
        2000)

        // setTimeout(async () => {
        //   // console.log('sendMessageArr',sendMessageArr.length)
        //   if(sendMessageArr.length > 0) {
        //     let getUsersObj:any = {}
        //     // const token = useAppSelector((state) => state.usersR.token)
        //     const dataUsers = await fetch(`http://${process.env.REACT_APP_DOM}/api/users/`, {
        //       method: 'GET',
        //       headers: {
        //         'Content-Type': 'application/json; charset=UTF-8',
        //         'Authorization': `Token ${token}`,
        //       },
        //     })
        //     .then((res) => res.json())
        //     .then((data) => {
        //       ;(data as Array<any>).map((el:any) => {
        //         getUsersObj[el.id] = el
        //       })
        //       return getUsersObj
        //     })
        //     // console.log(dataF)
  

  
  
        //   }
        // }, 3000)

			})
			.catch(e => {
				console.log(e)
				// const th = (document.getElementById(`lat-form__container`) as HTMLElement);
				// if (th) {
				// 	th.classList.add('lat-form__container_error')
				// }
				const th = document.querySelector('.sheets__send');
				// console.log(th)
				if (th) {
					th.classList.add('sheets__send_error')
          setSendDisable(false)
				}
				alert("Ошибка! Данные не отправлены");
			})

			// fetch('http://localhost:8000/api/statement/', {
			// 		method: 'get',
			// 		headers: {
			// 			'Content-Type': 'application/json; charset=UTF-8'
			// 		},
			// 	})
			// 	.then((res) => res.json())
			// 	.then((data) => {
			// 		console.log('data',data);
			// 		// console.log('date',typeof data[0]["pub_date"]);
			// 		// console.log('date',typeof data[1]["pub_date"]);
			// 		// console.log('date',Date.parse(data[2]["pub_date"]));
			// 		// const infoDate = (Date.parse(data[2]["pub_date"]) as unknown as Date)
			// 		// setStartDate(infoDate)
			// 		// data string YYYY-MM-DD
			// 		// console.log('data7',data[7].info)
			// 		// console.log('json',JSON.parse(data[7].info))

			// 	})
			// 	.catch((err) => console.log(err))
			// 	.finally(() => {});


      */
    }

	}

	// const onFon = () => {
	// 	const th = document.querySelector('.sheets__send');
	// 	console.log(th)
	// 	if (th) {
	// 		th.classList.add('sheets__send_success')
	// 	}
	// }

  const onSendModalYes = () => {
    // console.log('modal-Yes', this)
    setModal(false)
    onSend()
  }

  const onSendModalNo = () => {
    // console.log('modal-No', this)
    setModal(false)
  }

  const changeInputVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(setValueCash(value))
  }

  const newLesson = () => {
    resetDirection()
    resetTeachers()
    resetStudents()
  }

  const baseSend = async () => {
    
    const arrCoa = teachers.map(item => {
			return item.id
		})

		const arrDir = direction.map((item: any) => {
			return item.id
		})

    const nameDir = direction.map((item: any) => {
			return item.name
		})
		const nameCoa = teachers.map((item: any) => {
      if(item.fio) {
        return item.fio
      } else {
        const infoStu = JSON.parse(item.info)
        return infoStu.info
      }
		})

		const infoStat = {
			dir: nameDir,
			coa: nameCoa
		}

    const daySend = JSON.stringify(new Date()).substring(1,11)

    // List
    const dataSend: any = {
      dataStatement: {
        coath: arrCoa.toString(),
        direction: arrDir.toString(),
        number: '0',
        prefix: 'S',
        pub_date: sheetsDate,
        info: JSON.stringify(infoStat),
        'pub_date_proba_pera': daySend,
      },
      lessons: [],
      userName,
      infoStat
		}



    let arrLesson: Array<any> = []

    const studentsPay = [...students, ...tempStudents]
    for (let item of studentsPay) {
      const obj: any = {}
      obj.id_users = (item.id).toString();
      if (item.info) {
        const infoStu = JSON.parse(item.info)
        obj.info =  JSON.stringify({info:infoStu.info});
      } else if (item.fio) {
        obj.info = JSON.stringify({info:item.fio})
      } else {
        obj.info = JSON.stringify({info:''})
      }
      if (studentsNumPay[`${item.id}-stuNumPay`].balanceNew === 'бон') {
        obj.paymet = `${studentsNumPay[`${item.id}-stuNumPay`].type}*`;
      } else {
        obj.paymet = `${studentsNumPay[`${item.id}-stuNumPay`].type}`;
      }
      obj.value = studentsNumPay[`${item.id}-stuNumPay`].value;
      obj.prefix = 'L';
      obj.id_statement = '0';
      obj.number = '0'
      // console.log('obj', obj)
      arrLesson.push(obj)
      // if (obj.paymet == 'нал') {
      //   dataAlert.valCash++
      // } else if (obj.paymet == 'б/н') {
      //   dataAlert.valNonCash++
      // } else if (obj.paymet == 'абн') {
      //   dataAlert.valAbn++
      // } else if (obj.paymet == 'абн*') {
      //   dataAlert.valBonus++
      // } else if (obj.paymet == 'руч') {
      //   dataAlert.valHand.push(JSON.stringify(obj.value))
      // }
    }

    dataSend.lessons = arrLesson
    dataSend.valueCash = valueCash
    // console.log('dataSend', dataSend)
    const resStatement = await LatinaController.statement(dataSend)
    // console.log('resStatement', resStatement)
    return resStatement
  }
	
  return (
    <div className="List">
			<div className='lat-form'>
				<div className='lat-form__container lat-form__container_shadow'>
					<NavBar />
					<div className="lat-form__header header">
						<div className="header__left">
              <button onClick={newLesson}>
								Новая <br/> ведомость
							</button>
						</div>
						<div className="header__center">
							<img className="logo" src={mainLogo} alt='логотип'></img>
						</div>
						<div className="header__right">
							
						</div>
					</div>
					<div className="lat-form__sheets sheets">
						<div className="sheets__title">
							{`${titleM}`}
						</div>
						<div className="sheets__item">
							<div className="sheets__item-text">
								Дата
							</div>
							<div className="sheets__item-value">
								<DatePicker selected={new Date(sheetsDate)} onChange={(date) => dispatch(setSheetsDate((date as Date).toISOString()))} />
							</div>
						</div>
						<div className="sheets__item">
							<div className="sheets__item-text">
								Направление
							</div>
							<button onClick={resetDirection}>
								Очистить
							</button>
							<button onClick={onDirection} className={'sheets__btn'}>
								Добавить/обновить
							</button>
								{/* <NavLink className={'sheets__btn'} to='/teacher' state={{url: "http://localhost:8000/api/direction/", tagSearch: 'direction'}}>Добавить/обновить</NavLink> */}
						</div>
						<div>
							<ListOnly
								students={direction}
								tagSearch={'direction'}
							/>
						</div>

						<div className="sheets__item">
							<div className="sheets__item-text">
								Преподаватели
							</div>
							<button onClick={resetTeachers}>
								Очистить
							</button>
							<button onClick={onTeachers} className={'sheets__btn'}>
								Добавить/обновить
							</button>
								{/* <NavLink className={'sheets__btn'} to='/teacher' state={{url: "http://localhost:8000/api/users/", tagSearch: 'teacher'}}>Добавить/обновить</NavLink> */}
						</div>
						<div>
							<ListOnly
							students={teachers}
							/>
						</div>

						<div className="sheets__item">
							<div className="sheets__item-text">
								Ученики
							</div>
							<button onClick={resetStudents}>
								Очистить всех
							</button>
							<button onClick={onStudentsNew} className={'sheets__btn'}>
								Новые
							</button>
							<button onClick={onStudents} className={'sheets__btn'}>
								Выбрать
							</button>
								{/* <NavLink className={'sheets__btn'} to='/teacher' state={{url: "http://localhost:8000/api/direction/"}}>Добавить/обновить</NavLink> */}
						</div>
						<div className='sheets__list-stu'>
							<ListOnlyStu
							students={[...students, ...tempStudents]}
							/>
						</div>
            <div>
              В кассе наличных
              <input id='valueCashInput' onChange={changeInputVal} />
            </div>

						<div className="sheets__send">
							{/* <button onClick={onSend}>
								Отправить
							</button> */}
							<button disabled={sendDisable} onClick={() => setModal(true)}>
              Отправить
							</button>
						</div>
					</div>
          <Modal isOpened={modal} onModalClose={() => {setModal(false)}} title={`Вы хотите отправить?`} style={{}} >
            <div className='btn-modal'>
              <button className='btn-modal__elem' onClick={onSendModalYes}>
                Да
              </button>
              <button className='btn-modal__elem' onClick={onSendModalNo}>
                Нет
              </button>
              
            </div>
          </Modal>
				</div>
			</div>
    </div>
  );
}

export default List;
