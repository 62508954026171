import { FC, useState } from "react"
import NavBar from "../../components/NavBar"
import mainLogo from './../../img/logo_main.png';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setBithday, setList } from "../../redux/slices/noticeSlice/noticeSlice";
import { setLessonsInfo, setRender, setStatementInfo, setStatementList, setSubInfo, setSubList } from "../../redux/slices/noticeInfoSlice/noticeInfoSlice";
import { ILessonFull, IStatementFull, ISubsconstFull } from "../../types/Interface";
import './NoticeInfo.scss'
import LatinaController from "../../API/LatinaController";
import Modal from "../../components/Modal/Modal";



export const NoticeInfo: FC = () => {
  const dispatch = useAppDispatch()
  const token = useAppSelector((state) => state.usersR.token)
  const subInfo = useAppSelector((state) => state.noticeInfo.subInfo)
  const statementInfo: any = useAppSelector((state) => state.noticeInfo.statementInfo)
  const lessonsInfo = useAppSelector((state) => state.noticeInfo.lessonsInfo)
  const render = useAppSelector((state) => state.noticeInfo.render)
  const subList = useAppSelector((state) => state.noticeInfo.subList)
  const statementList = useAppSelector((state) => state.noticeInfo.statementList)
  const role = useAppSelector((state) => state.usersR.user.role)
  const userId = useAppSelector((state) => state.usersR.user.id)

  const ArrStu:Array<any> = []

  const roleAdmin = role === 'ADMIN'
  const roleSuperAdmin = role === 'SUPERADMIN'
  const roleModerator = role === 'MODERATOR'
  const roleTeacher = role === 'TEACHER'
  const roleUser = role === 'USER'
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState(0);
  const [modalText, setModalText] = useState('');


  const onSendModalNo = () => {
    // console.log('modal-No', this)
    setModal(false)
  }

  const onSendModalYes = () => {
    // console.log('modal-Yes', this)
    setModal(false)
    if(modalAction === 1) {
      // console.log('1')
      onExtension()
    }
    if(modalAction === 2) {
      // console.log('2')
      onDeleteSubscript()
    }
    if(modalAction === 3) {
      // console.log('2')
      onDeleteLesson()
    }
  }

  const onExtension = async () => {
    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {
      const sendData = {
        id: number
      }
      try {
        const res = await LatinaController.subscriptionExtension(sendData)
        console.log(res)
        if(res.res === false) {
          alert(`Абонемент НЕ продлен`)
        }
        if(res.res === true) {
          const dataTest2 = {
            login: process.env.REACT_APP_USER_ONE,
            message: `Абонемент продлен ${number}`
          }
      
          const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTest2)
          })
          alert(`Абонемент ${number} продлен`)
        }
      } catch (e) {
        console.log(e)
        alert(`Абонемент НЕ продлен`)
      }

    }
  }

  const onDeleteSubscript = async () => {
    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {
      const sendData = {
        id: number
      }
      try {
        const res = await LatinaController.subscriptionDelete(sendData)
        console.log(res)
        if(res.res === false) {
          alert(`Абонемент НЕ удален`)
        }
        if(res.res === true) {
          const dataTest2 = {
            login: process.env.REACT_APP_USER_ONE,
            message: `Абонемент удален ${number}`
          }
      
          const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTest2)
          })
          alert(`Абонемент ${number} удален`)
        }
      } catch (e) {
        console.log(e)
        alert(`Абонемент НЕ удален`)
      }

    }
  }

  const onDeleteLesson = async () => {
    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {
      const sendData = {
        id: number
      }
      try {
        const res = await LatinaController.lessonDelete(sendData)
        console.log(res)
        if(res.res === false) {
          alert(`Занятие НЕ удалено`)
        }
        if(res.res === true) {
          const dataTest2 = {
            login: process.env.REACT_APP_USER_ONE,
            message: `Занятие ${number} удалено`
          }
      
          const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataTest2)
          })
          alert(`Занятие ${number} удалено`)
        }
      } catch (e) {
        console.log(e)
        alert(`Занятие НЕ удалено`)
      }

    }
  }

  const onSubInfo = async () => {
    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {

      let subscription
      try {
        subscription = await LatinaController.subscriptionOne({id: Number(number)})

        dispatch(setSubInfo([subscription]))
        dispatch(setStatementInfo({}))
        dispatch(setLessonsInfo([]))
        dispatch(setRender(0))
      } catch (e) {
        console.log('sub', e)
        const data = {
          id: number,
          id_users:'',
          numberSubscription: number,
          number: number,
          balance: 0,
          price: 0,
          paymet: '',
          prefix: '',
          dateBegin: '',
          quantity: 0,
          isActive: false,
          info: JSON.stringify({info: 'Абонемент несуществует'}),
        }
        ArrStu.push(data)
        dispatch(setSubInfo(ArrStu))
        dispatch(setStatementInfo({}))
        dispatch(setLessonsInfo([]))
        dispatch(setRender(0))

      }



      console.log('subscription', subscription)

      // fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/${number}/`, {
      //   method: 'GET',
      //   headers: {
      //     accept: 'application/json',
      //     'Content-Type': 'application/json; charset=UTF-8',
      //     'Authorization': `Token ${token}`,
      //   },
      //   credentials: 'same-origin',
      // })
      // .then(async (res) => {
      //   // если не найдено приходит 404, но при этом если не обработать то дальше прокидывается ответ и не уходит в catch
      //   if(res.status < 400){
      //     return res.json()
      //   }
      //   else {
      //     const err = await res.json()
      //     throw Error(JSON.stringify(err))
      //   }
      // })
      // .then(dataSubsconst => {
      //   console.log('dataSubsconst', dataSubsconst)
      //   ArrStu.push(dataSubsconst)

      //   dispatch(setSubInfo(ArrStu))
      //   dispatch(setStatementInfo({}))
      //   dispatch(setLessonsInfo([]))
      //   dispatch(setRender(0))

      // })
      // .catch(e => {
      //   console.log('sub', e)
      //   const data = {
      //     id: number,
      //     id_users:'',
      //     item: number,
      //     number: number,
      //     balance: 0,
      //     price: 0,
      //     paymet: '',
      //     prefix: '',
      //     pub_date: '',
      //     quantity: 0,
      //     status: false,
      //     info: JSON.stringify({info: 'Абонемент несуществует'}),
      //   }
      //   ArrStu.push(data)
      //   dispatch(setSubInfo(ArrStu))
      //   dispatch(setStatementInfo({}))
      //   dispatch(setLessonsInfo([]))
      //   dispatch(setRender(0))
      // })
    } else {
      dispatch(setSubInfo([]))
      dispatch(setStatementInfo({}))
      dispatch(setLessonsInfo([]))
    }




    
  }
  
  const listItems = subInfo?.map((item: any, index: any) => {
    console.log('item',item)
    const info = item ? JSON.parse(item.info) : ''
    let dateBonusParty: any[] = []
    let dateLessonFull: any[] = []
    let dateLesson: any[] = []
    if(info && info.dateBonusParty) {
      dateBonusParty = [...info.dateBonusParty]
    }
    if(info && info.lessons) {
      dateLessonFull = [...info.lessons]
    }
    if(info && info.date) {
      dateLesson = [...info.date]
    }
    let dateEnd
    if(item.dateBegin) {
      if(item.price === 2500) {
        const oldDate = new Date(Date.parse(item.dateBegin))
        let dateKorrekt = 0
        if(process.env.REACT_APP_KORREKT_DATE) {
          dateKorrekt = Number(process.env.REACT_APP_KORREKT_DATE)
        }

        const newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
        dateEnd = new Date(newDate.setDate(newDate.getDate()+dateKorrekt))
      } else {
        const oldDate = new Date(Date.parse(item.dateBegin))
        dateEnd =  new Date(oldDate.setFullYear(oldDate.getFullYear()+1))
      }
    }
    console.log('dfa', dateEnd, item.dateBegin)
    return <div key={index}>
      <div>
        <span>Абонемент: </span>
        <span><b>{item.numberSubscription}</b></span>
      </div>
      <div>
        <span>ФИО: </span>
        <span><b>{info ? info.info : 'не оформлен'}</b></span>
      </div>
      <div>
        <span>Тип: </span>
        <span><b>{item.price === 2500 ? 'сгораемый' : 'годовой'}</b></span>
        <span><b>({item.price})</b></span>
      </div>
      <div>
        <span>Дата начала: </span>
        <span><b>{item.dateBegin ? item.dateBegin.slice(0,10) : ''}</b></span>
      </div>
      <div>
        <span>Дата конца: </span>
        <span><b>{dateEnd ? dateEnd.toISOString().slice(0,10) : ''}</b></span>
      </div>
      <div>
        <span>Баланс: </span>
        <span><b>{item.balance}</b></span>
        <span> из <b>{item.quantity}</b></span>
      </div>
      <div>
        <span>Статус: </span>
        <span><b>{item.isActive ? 'действующий' : 'не действующий'}</b></span>
      </div>
      { item.price == 2500 ? 
                <>

                  <div>
                    <span>
                      Бонусная вечеринка:
                    </span>
                      {dateBonusParty.length > 0 ? dateBonusParty.map((it, ind) => <div key={ind}><b>{it.substring(0,10)}</b></div>) : <span> - </span>}
                  </div>
                </>
                : <></>
      }
      <div className="lat-office_lesson">
        <p>
          Даты посещений:
        </p>
        {dateLessonFull && dateLessonFull.length > 0 ? (
              dateLessonFull.map((it, ind) => <p key={ind}>{it.date.substring(0,10)}{' '}{it.name}</p>)
            ) : (
              dateLesson.map((it, ind) => <p key={ind}>{it}</p>)
              //{/* {dateLesson.map(it => <p>{`${new Date(Date.parse(it))}`}</p>)} */}
              
          )
        }

      </div>
    </div>;
    }
  )

  const onStatementInfo = async () => {
    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {

      let statement
      try {
        if(roleAdmin || roleSuperAdmin || roleModerator) {
          statement = await LatinaController.statementOne({id: Number(number)})
        } else {
          statement = await LatinaController.statementTeacherOne({id: Number(number), teacherId: userId})
        }


        console.log('statement', statement)


        dispatch(setLessonsInfo(statement.lesson))


        const infoStatement = JSON.parse(statement.info)
        const directionArrName = infoStatement ? infoStatement.dir : []
        const coachArrName = infoStatement ? infoStatement.coa : []
        const dateStatement = statement.date.slice(0,10)
        const numberStatement = statement.id


        dispatch(setSubInfo([]))
        dispatch(setStatementInfo({directionArrName,coachArrName,dateStatement,numberStatement}))

        dispatch(setRender(1))

      } catch (e) {
        console.log('sub', e)
        const directionArrName: any[] = []
        const coachArrName: any[] = []
        const dateStatement = 'ведомость несуществует'
        const numberStatement = number
        dispatch(setSubInfo([]))
        dispatch(setStatementInfo({directionArrName,coachArrName,dateStatement,numberStatement}))
        dispatch(setLessonsInfo([]))
        dispatch(setRender(1))
      }


      // fetch(`http://${process.env.REACT_APP_DOM}/api/statement/${number}/`, {
      //   method: 'GET',
      //   headers: {
      //     accept: 'application/json',
      //     'Content-Type': 'application/json; charset=UTF-8',
      //     'Authorization': `Token ${token}`,
      //   },
      //   credentials: 'same-origin',
      // })
      // .then((res) => res.json())
      // .then(async (dataStatement: IStatementFull) => {
      //   const infoStatement = JSON.parse(dataStatement.info)
      //   const directionArrName = infoStatement ? infoStatement.dir : []
      //   const coachArrName = infoStatement ? infoStatement.coa : []
      //   const dateStatement = dataStatement.pub_date.slice(0,10)
      //   const numberStatement = dataStatement.id

      //   const lessonsAll: ILessonFull[] = await lessons(numberStatement)
      //   dispatch(setLessonsInfo(lessonsAll))


      //   console.log(dateStatement)
      //   console.log(lessonsAll)

      //   dispatch(setSubInfo([]))
      //   dispatch(setStatementInfo({directionArrName,coachArrName,dateStatement,numberStatement}))

      //   dispatch(setRender(1))

      // })
      // .catch(e => {
      //   console.log('sub', e)
      //   const directionArrName: any[] = []
      //   const coachArrName: any[] = []
      //   const dateStatement = 'ведомость несуществует'
      //   const numberStatement = number
      //   dispatch(setSubInfo([]))
      //   dispatch(setStatementInfo({directionArrName,coachArrName,dateStatement,numberStatement}))
      //   dispatch(setLessonsInfo([]))
      //   dispatch(setRender(1))
      // })
    } else {
      dispatch(setSubInfo([]))
      dispatch(setStatementInfo({}))
      dispatch(setLessonsInfo([]))
    }

  }

  const listStatement = () => {
    
    return(
      <>
        <div>
          <span>Ведомость: </span>
          <span><b>{statementInfo.numberStatement}</b></span>
        </div>
        <div>
          <span>Дата заполнения: </span>
          <span><b>{statementInfo.dateStatement}</b></span>
        </div>
        <div>
          <div>Направление: </div>
          {statementInfo.directionArrName ? statementInfo.directionArrName.map((it:any, ind:any) => <div key={ind}><b>{it}</b></div>) : <></>}
        </div>
        <div>
          <div>Преподаватели: </div>
          {statementInfo.coachArrName ? statementInfo.coachArrName.map((it:any, ind:any) => <div key={ind}><b>{it}</b></div>) : <></>}
        </div>
        <div>
          <div>Ученики: </div>
          {lessonsInfo.length > 0 ? lessonsInfo.map((it:any, ind:any) => {
            const info = JSON.parse(it.info)
            return <>
              <div key={ind} className='notice-lesson-container'>
                {(roleSuperAdmin || roleAdmin || roleModerator) ? (
                  <>
                    <span><b>{ind + 1} - {it.number} </b></span>
                  </>
                ) : (
                  <>
                    <span><b>{ind + 1}</b></span>
                  </>
                )}
                <span><b> {info.info} </b></span>
                <span><b>{it.value}{it.payment === 'абн*' ? '*' : ''}</b></span>
              </div>
            </> 
          }) : <></>}
        </div>
    
      </>)
  }


  // const lessons = async (numberStatement: number) => {
  //   const less = fetch(`http://${process.env.REACT_APP_DOM}/api/lesson/`, {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       'Content-Type': 'application/json; charset=UTF-8',
  //       'Authorization': `Token ${token}`,
  //     },
  //     credentials: 'same-origin',
  //   })
  //   .then((res) => res.json())
  //   .then((dataLessonAll: ILessonFull[]) => {
  //     return dataLessonAll.filter((el) => el.id_statement === numberStatement.toString())
  //   })
  //   return less
  // }

  const onSubListInfo = async () => {

    
    const dataSendStat = {
      id: 1
    }

    let dataSubsconstAll = await LatinaController.subscriptionAll(dataSendStat)
    dataSubsconstAll.reverse()

    console.log('dataSubsconstAll', dataSubsconstAll)

    // let dataSubsconstAll: Array<any> = await fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    //   credentials: 'same-origin',
    // })
    // .then(async (res) => {
    //   // если не найдено приходит 404, но при этом если не обработать то дальше прокидывается ответ и не уходит в catch
    //   if(res.status < 400){
    //     return res.json()
    //   }
    //   else {
    //     const err = await res.json()
    //     throw Error(JSON.stringify(err))
    //   }
    // })
    // .then(dataSubsconstAll => {
    //   return dataSubsconstAll
    // })
    // .catch(e => {
    //   console.log('sub', e)
    // })


    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {
      // console.log(dataSubsconstAll.slice(0,10))
      const num = Number(number)
      console.log(dataSubsconstAll.length)
      if (num > 0 && num < dataSubsconstAll.length - 8) {
        dataSubsconstAll = dataSubsconstAll.reverse().slice(num - 1,num + 9).reverse()
      } else {
        dataSubsconstAll = dataSubsconstAll.slice(0,10)
      }
      
    } else {
      dataSubsconstAll = dataSubsconstAll.slice(0,10)
    }
    
    console.log(dataSubsconstAll)
    dispatch(setSubList(dataSubsconstAll))
    dispatch(setRender(2))

  }

  const subListItems = subList?.map((item: any, index: any) => {
    console.log('item2', item)
    const info = item ? JSON.parse(item.info) : ''
    return <div key={index} className='notice-lesson-container'>
      <div style={ item.isActive ? {width: '55px', color: 'green'} : {width: '70px', color: 'red'}}>
        <span><b>{item.numberSubscription}</b></span>
      </div>
      <div>
        <span><b>{item.balance}</b></span>
      </div>
      <div style={{width: '90px'}}>
        <span><b>{item.dateBegin.slice(0,10)}</b></span>
      </div>
      <div style={{maxWidth: '140px'}}>
        <span><b>{info ? info.info : 'не оформлен'}</b></span>
      </div>
    </div>
  })


  const onStatementListInfo = async () => {


    
    // let dataSubsconstAll: Array<any> = await fetch(`http://${process.env.REACT_APP_DOM}/api/statement/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    //   credentials: 'same-origin',
    // })
    // .then(async (res) => {
    //   // если не найдено приходит 404, но при этом если не обработать то дальше прокидывается ответ и не уходит в catch
    //   if(res.status < 400){
    //     return res.json()
    //   }
    //   else {
    //     const err = await res.json()
    //     throw Error(JSON.stringify(err))
    //   }
    // })
    // .then(dataSubsconstAll => {
    //   return dataSubsconstAll
    // })
    // .catch(e => {
    //   console.log('sub', e)
    // })

    // console.log('dataSubsconstAll', dataSubsconstAll)

    const dataSendStat = {
      id: userId
    }

    let dataSubsconstAll
    if(roleAdmin || roleSuperAdmin || roleModerator) {
      dataSubsconstAll = await LatinaController.statementAll(dataSendStat)
    } else {
      dataSubsconstAll = await LatinaController.statementTeacher(dataSendStat)
    }
    dataSubsconstAll.reverse()

    // console.log('stat', stat)

    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {
      // console.log(dataSubsconstAll.slice(0,10))
      const num = Number(number)
      console.log(dataSubsconstAll.length)
      if (num > 0 && num < dataSubsconstAll.length - 8) {
        dataSubsconstAll = dataSubsconstAll.reverse().slice(num - 1,num + 9).reverse()
      } else {
        dataSubsconstAll = dataSubsconstAll.slice(0,10)
      }
      
    } else {
      dataSubsconstAll = dataSubsconstAll.slice(0,10)
    }
    
    console.log('dataSubsconstAll33', dataSubsconstAll)
    dispatch(setStatementList(dataSubsconstAll))
    dispatch(setRender(3))
  }

  const statementListItems = statementList?.map((item: any, index: any) => {
    console.log('item2', item)
    const info = item ? JSON.parse(item.info) : ''
    return <div key={index} className='notice-lesson-container'>
      <div style={{maxWidth: '37px'}}>
        <span><b>{item.id}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.date.slice(0,10)}</b></span>
      </div>
      <div style={{maxWidth: '105px'}}>
        <span><b>{info ? info.dir[0] : 'не оформлен'}</b></span>
      </div>
      {info ? <>
        <div style={{maxWidth: '105px'}}>
          <span><b>{info.coa.length > 1 ? `${info.coa[0]} ${info.coa[1]}` : `${info.coa[0]}`}</b></span>
        </div>
      </> : <></>}
    </div>
  })


  const onRender = () => {

    switch(render) {
      case 0:  // if (x === 'value1')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              {listItems}
            </div>
          </div>
        </>
        break
    
      case 1:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              {lessonsInfo ? listStatement() : <></>}
            </div>
          </div>
        </>
        break
    
      case 2:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              <div className='notice-lesson-container'>
                <div style={{width: '55px'}}>
                  <span><b>{'Абн'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Баланс'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Дата'}</b></span>
                </div>
                <div style={{maxWidth: '140px'}}>
                  <span><b>{'ФИО'}</b></span>
                </div>
              </div>
              {subListItems}
            </div>
          </div>
        </>
        break
    
      case 3:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
            <div className='notice-lesson-container'>
                <div style={{width: '55px'}}>
                  <span><b>{'Номер'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Дата'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Направ.'}</b></span>
                </div>
                <div style={{maxWidth: '140px'}}>
                  <span><b>{'ФИО'}</b></span>
                </div>
              </div>
              {statementListItems}
            </div>
          </div>
        </>
        break
    
      default:
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              {lessonsInfo ? listStatement() : <></>}
            </div>
          </div>
        </>
        break
    }
  }

  return (
    <>
      <div className="List">
        <div className='lat-form'>
          <div className='lat-form__container lat-form__container_shadow'>
            <NavBar />
            <div className="lat-form__header header">
              <div className="header__left">

              </div>
              <div className="header__center">
                <img className="logo" src={mainLogo} alt='логотип'></img>
              </div>
              <div className="header__right">

              </div>
            </div>
            <div className="lat-form__sheets sheets">
              <div className="sheets__title">
                {`Информация`}
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text-info">
                  Номер 
                  <span className='sign-up-primer'> (Только цифры)</span>
                </div>
                <input id='number' />
              </div>
                {onRender()}
              <div className="sheets__send">
                <button onClick={onSubListInfo}>
                  Список абн.
                </button>
                <button onClick={onSubInfo}>
                  Абонемент
                </button>
                <button onClick={onStatementInfo}>
                  Ведомость
                </button>
                <button onClick={onStatementListInfo}>
                  Список вед.
                </button>
                {(roleSuperAdmin || roleAdmin || roleModerator) 
                ? <>
                <button onClick={() => {
                  const number =  (document.querySelector('#number') as HTMLInputElement).value
                  const reg = /^\d+$/
                  if(reg.test(number.toString())) {
                    setModalText(`Вы хотите продлить абонемент ${number}?`)
                  } else {
                    setModalText(`Вы хотите продлить абонемент?`)
                  }
                  setModalAction(1)
                  setModal(true)
                  }}>
                  Продлить Абн
                </button>
                <button onClick={() => {
                  const number =  (document.querySelector('#number') as HTMLInputElement).value
                  const reg = /^\d+$/
                  if(reg.test(number.toString())) {
                    setModalText(`Вы хотите удалить абонемент ${number}?`)
                  } else {
                    setModalText(`Вы хотите удалить абонемент?`)
                  }
                  setModalAction(2)
                  setModal(true)
                  }}>
                  Удалить Абн
                </button>
                <button onClick={() => {
                  const number =  (document.querySelector('#number') as HTMLInputElement).value
                  const reg = /^\d+$/
                  if(reg.test(number.toString())) {
                    setModalText(`Вы хотите удалить занятие ${number}?`)
                  } else {
                    setModalText(`Вы хотите удалить занятие?`)
                  }
                  setModalAction(3)
                  setModal(true)
                  }}>
                  Удалить Занятие
                </button>
                <Modal isOpened={modal} onModalClose={() => {setModal(false)}} title={modalText} style={{}} >
                  <div className='btn-modal'>
                    <button className='btn-modal__elem' onClick={onSendModalYes}>
                      Да
                    </button>
                    <button className='btn-modal__elem' onClick={onSendModalNo}>
                      Нет
                    </button>
                    
                  </div>
                </Modal>
                </> 
                : <>
                </>}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
