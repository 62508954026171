import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api, { signInNew } from '../../API';
import NavBar from '../../components/NavBar';
import { authorization } from '../../controller/userController';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resLogged, setLoggedIn, setToken, setUser, setUsersR } from '../../redux/slices/userSlice';
import mainLogo1 from './../../img/logo_main_1.png';
import LatinaController from '../../API/LatinaController';
import { SubmitHandler, useForm } from 'react-hook-form';
import Field from '../../components/Field/Field';
import { UserService } from '../../services/user.service';

interface MainProps {
  title?: string;
}

export interface ISignUpForm {
  login: string
  name: string
  email: string
  phone: string
  date: string
  linkVk: string
  promo: string
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SignUp: FC<MainProps> = ({
  title= "Регистрация"
}) => {

  const loggedIn = useAppSelector((state) => state.usersR.loggedIn)
  const token = useAppSelector((state) => state.usersR.token)
  const userName = useAppSelector((state) => state.usersR.user.fio)
  const dispatch = useAppDispatch();

  const [sendDisable, setSendDisable] = useState(false);
  
  const { register: formRegister, handleSubmit, formState: {errors} , reset, watch, setValue } = useForm<ISignUpForm>({ mode: "onChange" })

  let query = useQuery();

  let navigate = useNavigate();

  useEffect(()=> {

    auth()

    const login = query.get("login") || ''
    const name = query.get("name") || ''
    const email = query.get("email") || ''
    const phone = query.get("phone") || ''
    const date = query.get("date") || ''
    const linkVk = query.get("linkVk") || ''
    const promo = query.get("promo") || ''
    setValue('login',login)
    setValue('name',name)
    setValue('email',email)
    setValue('phone',phone)
    setValue('date',date)
    setValue('linkVk',linkVk)
    setValue('promo',promo)

  }, [])


  const auth = async () => {
    try {
      const profile = await UserService.getProfile()
      // const me = await LatinaController.me()
      // console.log(me)
      // console.log('profile', profile.data)
      // api.getUserData()
      // .then((res: any) => {
      // })
      // .catch(err => {
      //   localStorage.removeItem('token')
      //   dispatch(setLoggedIn(false));
      //   dispatch(resLogged());
      // })
    } catch (e) {
      localStorage.removeItem('token')
      dispatch(setLoggedIn(false));
      dispatch(resLogged());
      navigate('/');
    }
  }

  const AuthApp = async (data: ISignUpForm) => {
    await AuthBase(data)

  }

  const titleM = title;
  const Auth = (data: ISignUpForm) => {

    const username = data.login
    const fio = data.name
    const email = data.email
    const phone = data.phone
    const birthday = data.date
    const link_vk = data.linkVk
    const password = process.env.REACT_APP_DEFAULT_PASSWORD

    // const username = (document.querySelector('#login') as HTMLInputElement).value
    // const fio = (document.querySelector('#fio') as HTMLInputElement).value
    // const email = (document.querySelector('#email') as HTMLInputElement).value
    // const phone = (document.querySelector('#phone') as HTMLInputElement).value
    // const birthday = (document.querySelector('#birthday') as HTMLInputElement).value
    // const link_vk = (document.querySelector('#vk') as HTMLInputElement).value
    // const password = process.env.REACT_APP_DEFAULT_PASSWORD
    const dataSend = {
      username,
      password,
      email,
      phone,
      fio,
      birthday,
      link_vk,
      nickname: username,
      number: 1,
      prefix: 'U',
      balance: 0,
    }

    fetch(`${process.env.REACT_APP_DOM}/api/users/`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json; charset=UTF-8',
						// 'Authorization': `Token ${token}`,
					},
					body: JSON.stringify(dataSend)
				})
        .then((res) => {
          if (res.status >= 400) {
            const response = async () => await res.json()
            response().then(result => {
              // console.log('res', result)
              alert (`
              Ошибка:
              Логин - ${result.username ? result.username[0] : ''};
              Почта - ${result.email ? result.email[0] : ''};
              Телефон - ${result.phone ? result.phone[0] : ''};
              Дата рождения - ${result.birthday ? result.birthday[0] : ''};
              `)

            })
            return false
          } else {
            return res.json()
          }
        })
        .then(dataUser => {
          if(dataUser != false) {


            dataUser.number = dataUser.id
            // fetch(`http://${process.env.REACT_APP_DOM}/api/users/${dataUser.id}/`, {
            //   method: 'PATCH',
            //   headers: {
            //     'Content-Type': 'application/json; charset=UTF-8',
            //     'Authorization': `Token ${token}`,
            //   },
            //   body: JSON.stringify(dataUser)
            // })
          }
          return dataUser
        })
        .then(dataUser => {
          if(dataUser != false) {

            const dataStu = {
              number: dataUser.id,
              prefix: 'STU',
              id_users: dataUser.id,
              direction: '',
              subscrip: '',
              info: JSON.stringify({info: dataUser.fio})
            }


            fetch(`${process.env.REACT_APP_DOM}/api/student/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Token ${token}`,
              },
              body: JSON.stringify(dataStu)
            })
          }
          return dataUser
        })
        .then(dataUser => {
          if(dataUser != false) {
            const loginUser = data.login
            const fio = data.name
            const email = data.email
            const phone = data.phone
            const birthday = data.date
            const link_vk = data.linkVk

            const password = process.env.REACT_APP_DEFAULT_PASSWORD

            // const username = (document.querySelector('#login') as HTMLInputElement)
            // const fio = (document.querySelector('#fio') as HTMLInputElement)
            // const email = (document.querySelector('#email') as HTMLInputElement)
            // const phone = (document.querySelector('#phone') as HTMLInputElement)
            // const birthday = (document.querySelector('#birthday') as HTMLInputElement)
            // const link_vk = (document.querySelector('#vk') as HTMLInputElement)
            

            const dataSendLog = {
              login: process.env.REACT_APP_USER_ONE,
              message: `Пользователь ${fio} добавлен
Логин - ${loginUser}
Пароль - ${password}
Профиль ВК: ${link_vk}
Добавил преподаватель: ${userName}
`
            }
        
            // console.log(dataSend)
        
            const resLog = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram/log-write`, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataSendLog)
            })

            const dataSend = {
              login: process.env.REACT_APP_USER_ONE,
              message: `Пользователь ${fio} добавлен
Логин - ${loginUser}
Пароль - ${password}
Профиль ВК: ${link_vk}
Добавил преподаватель: ${userName}
`
            }
        
            // console.log(dataSend)
        
            const res = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataSend)
            })
        
            const dataSend2 = {
              login: process.env.REACT_APP_USER_TWO,
              message: `Пользователь ${fio} добавлен
Логин - ${loginUser}
Пароль - ${password}
Профиль ВК: ${link_vk}
Добавил преподаватель: ${userName}
`
            }
        
        
            const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataSend2)
            })


            alert(
              `Пользователь ${fio} добавлен
              Логин - ${loginUser}
              Пароль - ${password}
              `)

            // username.value = ''
            // fio.value = ''
            // email.value = ''
            // phone.value = ''
            // birthday.value = ''
            // link_vk.value = ''
          }
        })
        .catch(e => {
          console.log('user-sign-up', e)
        })



    // console.log('data',data)
    // authorization(data)

  }

  const AuthBase = async (data: ISignUpForm) => {
    const dataSend = {
      data
    }

    let resUser

    try {
      resUser = await LatinaController.userCreate(dataSend)
      console.log('resUser', resUser)

        const loginUser = data.login
        const fio = data.name
        const link_vk = data.linkVk

        

        const dataSendLog = {
          login: process.env.REACT_APP_USER_ONE,
          message: `Пользователь ${fio} добавлен
Логин - ${loginUser}
Профиль ВК: ${link_vk}
Добавил преподаватель: ${userName}
`
        }
    

    
        const resLog = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram/log-write`, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataSendLog)
        })

        const dataSendTel = {
          login: process.env.REACT_APP_USER_ONE,
          message: `Пользователь ${fio} добавлен
Логин - ${loginUser}
Профиль ВК: ${link_vk}
Добавил преподаватель: ${userName}
`
        }
    

    
        const res = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataSendTel)
        })
    
        const dataSend2 = {
          login: process.env.REACT_APP_USER_TWO,
          message: `Пользователь ${fio} добавлен
Логин - ${loginUser}
Профиль ВК: ${link_vk}
Добавил преподаватель: ${userName}
`
        }
    
    
        const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/api/v2/bot-telegram`, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataSend2)
        })


        alert(
          `Пользователь ${fio} добавлен
          Логин - ${loginUser}
          Пароль - ${process.env.REACT_APP_DEFAULT_PASSWORD}
          `)

        reset()


    } catch (e: any) {
      console.log('Error', e)
      if(e) {
        if(e.response.data.message.text) {
          alert(e.response.data.message.text)
        } else {
          alert(JSON.stringify(e.response.data))
        }
      }
    }

    if(resUser) {
      console.log('ХОРОШО')
      // await Auth(data)
      // тут будет регистрация в старую БД
    } else {
      console.log('ПЛОХО')
    }
  }


  const AuthClose = () => {
    // onSignOut()
    navigate('/')
  }

  const validLogin = /^[a-zA-Z0-9_-]+$/
  const validName = /^[а-яА-ЯёЁ -]+$/
  const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const validPhone = /^[0-9]{11}$/
  const validDate = /^[0-9]{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/

  const onSubmit: SubmitHandler<ISignUpForm> = (data) => {
    // console.log(data)

    AuthApp(data)

    // reset()
  }

  return (
    <div className="Main">
      <div className='lat-form'>
        <div className='lat-form__container lat-form__container_shadow'>
          <NavBar />
          <img className="logo" src={mainLogo1} alt='логотип'></img>
          <div className='lat-form__box'>


            <div>
            {titleM}
            </div>
            {loggedIn ?
              <div className='loging'>
                {/* <label htmlFor='login'>Логин</label>
                <input id='login'></input>
                <label htmlFor='fio'>ФИО</label>
                <input id='fio'></input>
                <label htmlFor='email'>E-mail</label>
                <input id='email'></input>
                <label htmlFor='phone'>Телефон</label>
                <input id='phone'></input>
                <label htmlFor='birthday'>Дата рождения</label>
                <input id='birthday'></input>
                <label htmlFor='vk'>Профиль ВК</label>
                <input id='vk'></input> */}
                {/* <label htmlFor='password'>Пароль</label>
                <input id='password'></input> */}
                {/* <button onClick={Auth}>Зарегистрироваться</button> */}

                <form onSubmit={handleSubmit(onSubmit)} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >



                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: ironman'}
                  </div>
                  <Field
                    {...formRegister('login', {
                      required: 'Логин обязательное поле',
                      pattern: {
                        value: validLogin,
                        message: 'Допустимы латиница, _ и -'
                      }
                    })}
                    placeholder='Логин'
                    error={errors.login?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: Иванов Иван Иваныч'}
                  </div>
                  <Field
                    {...formRegister('name', {
                      required: 'ФИО обязательное поле',
                      pattern: {
                        value: validName,
                        message: 'Допустимы кириллица, -, пробел'
                      }
                    })}
                    placeholder='ФИО'
                    error={errors.name?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: ironman@gmail.com'}
                  </div>
                  <Field
                    {...formRegister('email', {
                      required: 'Email обязательное поле',
                      pattern: {
                        value: validEmail,
                        message: 'Некорректный формат почты'
                      }
                    })}
                    placeholder='Почта'
                    error={errors.email?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: Пример: 88001234567'}
                  </div>
                  <Field
                    {...formRegister('phone', {
                      required: 'Телефон обязательное поле',
                      pattern: {
                        value: validPhone,
                        message: 'Допустимы только цифры'
                      }
                    })}
                    placeholder='Телефон'
                    error={errors.phone?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: 2023-01-31'}
                  </div>
                  <Field
                    {...formRegister('date', {
                      required: 'Дата рождения обязательное поле',
                      pattern: {
                        value: validDate,
                        message: 'Формат обязателен: ГГГГ-ММ-ДД'
                      }
                    })}
                    placeholder='Дата рождения'
                    error={errors.date?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: https://vk.com/id1'}
                  </div>
                  <Field
                    {...formRegister('linkVk')}
                    placeholder='Профиль vk'
                    error={errors.linkVk?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Необязательное поле'}
                  </div>
                  <Field
                    {...formRegister('promo')}
                    placeholder='Дополнительное поле'
                    error={errors.promo?.message}
                  />


                  <div>
                    <button disabled={sendDisable} type='submit'>Зарегистировать</button>

                  </div>
                </form>
              </div>
              :
              <button onClick={AuthClose}>Войти</button>
            }
            <div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
