import React, { FC, useEffect } from 'react';
import api, { signInNew } from '../../API';
import NavBar from '../../components/NavBar';
import { authorization } from '../../controller/userController';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resLogged, setLoggedIn, setToken, setUser, setUsersR } from '../../redux/slices/userSlice';
import mainLogo1 from './../../img/logo_main_1.png';
import './Main.scss';
import LatinaController from '../../API/LatinaController';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user.service';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

interface MainProps {
  title?: string;
}

const Main: FC<MainProps> = ({
  title= "Главная"
}) => {

  const loggedIn = useAppSelector((state) => state.usersR.loggedIn)
  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  useEffect(()=> {

    auth()

}, [])

const auth = async () => {
  try {
    const profile = await UserService.getProfile()
    // const me = await LatinaController.me()
    // console.log(me)
    // console.log('profile', profile.data)
    // api.getUserData()
    // .then((res: any) => {
    // })
    // .catch(err => {
    //   localStorage.removeItem('token')
    //   dispatch(setLoggedIn(false));
    //   dispatch(resLogged());
    // })
  } catch (e) {
    localStorage.removeItem('token')
    dispatch(setLoggedIn(false));
    dispatch(resLogged());
    navigate('/');
  }
}

  const authorization = (data: signInNew) => {
    const { username, password } = data;
    api.signin({
      username, password
    }).then((res:any) => {
      if (res.auth_token) {
        dispatch(setToken(res.auth_token))
        // localStorage.setItem('token', res.auth_token)
        api.getUserData()
          .then((res: any) => {
            // console.log('res',res);
            dispatch(setUser(res))
            // dispatch(setUsersR({usersRed: res}))
            dispatch(setLoggedIn(true));
          })
          .catch(err => {
            dispatch(setLoggedIn(false));
            // history.push('/signin')
          })
      } else {
        dispatch(setLoggedIn(false));
      }
    })
    .catch(err => {
      const errors = Object.values(err)
      if (errors) {
        alert(errors.join(', '))
      }
      dispatch(setLoggedIn(false));
    })
  }

  const onSignOut = () => {
    dispatch(setLoggedIn(false));
    dispatch(resLogged());
    // api
    //   .signout()
    //   .then(res => {
    //     localStorage.removeItem('token')
    //     dispatch(setLoggedIn(false));
    //     dispatch(resLogged());
    //   })
    //   .catch(err => {
    //     const errors = Object.values(err)
    //     if (errors) {
    //       alert(errors.join(', '))
    //     }
    //     dispatch(setLoggedIn(false));
    //   })
  }


  const titleM = title;
  const Auth = async () => {
    const log = (document.querySelector('#login') as HTMLInputElement).value
    const pas = (document.querySelector('#password') as HTMLInputElement).value
    const data = {
      username:log,
      password:pas
    }
    // console.log('data',data)
    // authorization(data)

    const dataSend = {
      login: log,
      password: pas
    }

    
    toast('Ожидайте');
    try {
      const res = await AuthService.main('login',dataSend)
      toast('Получение профиля');
      if(res) {
        // console.log('res', res)
        // alert('Успешно')
        // toast('Получение токена');
        // const tok = await LatinaController.token()
        // toast('Данные получены');
        // console.log('tok', tok)
        // const profile = await UserService.getProfile()
        const resUser = {
          email: res.user.email,
          id: res.user.id,
          username: res.user.login,
          fio: res.user.name,
          nickname: res.user.name,
          role: res.user.roles[0],
        }
        dispatch(setLoggedIn(true));
        dispatch(setUser(resUser))
        toast('Успешно!');
      }
    } catch (e: any) {
      toast('Ошибка!');
      if(e) {
        console.log(e.response.data.message)
        alert(`${e.response.data.message} \nПроверьте логин и пароль`)
      }
      // const errors = Object.values(e)
      // if (errors) {
      //   // alert(errors?.message.join(', '))
      // }
      dispatch(setLoggedIn(false));
      dispatch(setUser({}))
    }

    // res.then((res:any) => {
    //   if (res.auth_token) {
    //     dispatch(setToken(res.auth_token))
    //     // localStorage.setItem('token', res.auth_token)
    //     api.getUserData()
    //       .then((res: any) => {
    //         // console.log('res',res);
    //         dispatch(setUser(res))
    //         // dispatch(setUsersR({usersRed: res}))
    //         dispatch(setLoggedIn(true));
    //       })
    //       .catch(err => {
    //         dispatch(setLoggedIn(false));
    //         // history.push('/signin')
    //       })
    //   } else {
    //     dispatch(setLoggedIn(false));
    //   }
    // })
    // .catch(err => {
    //   const errors = Object.values(err)
    //   if (errors) {
    //     alert(errors.join(', '))
    //   }
    //   dispatch(setLoggedIn(false));
    // })



    // const res = await LatinaController.authLogin(dataSend)
    
    // console.log('auth', res)
  }


  const AuthClose = () => {
    onSignOut()
  }

  return (
    <div className="Main">
      <div className='lat-form'>
        <div className='lat-form__container lat-form__container_shadow'>
          <NavBar />
          <img className="logo" src={mainLogo1} alt='логотип'></img>
          <div className='lat-form__box'>


            <div>
            {titleM}
            </div>
            {!loggedIn ?
              <div className='loging'>
                <label htmlFor='login'>Логин</label>
                <input id='login'></input>
                <label htmlFor='password'>Пароль</label>
                <input id='password' type={'password'}></input>
                <button onClick={Auth}>Войти</button>
              </div>
              :
              <button onClick={AuthClose}>Выйти</button>
            }
            <div className='main-info'>
              <div className='main-info__item'>
                Танцевальная студия Lege Artis. Kiz48 
              </div>
              <div className='main-info__item'>
                Телефон <a href='tel:+79202406052'>89202406052</a>
              </div>
              <div className='main-info__item'>
                Адрес: улица А.Д. Белянского, с7
              </div>
              <div className='main-info__item'>
                Кизомба - Липецк
              </div>
              <div className='main-info__item'>
                
                <a href='https://vk.com/legeartisdance' target={'_blank'}>Группа Lege Artis в ВК </a>
              </div>
              {/* <div className='main-info__item'>
                
                <a href='https://vk.com/kiz48' target={'_blank'}>Группа kiz48 в ВК</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="top-center"
      />
    </div>
  );
}

export default Main;
